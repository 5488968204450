import { useRive, useStateMachineInput } from "@rive-app/react-canvas";

import { useEffect } from "react";

const RiveWrapper = (loadingValue) => {
  const { rive, RiveComponent } = useRive({
    src: require("../img/toilet_paper_loader.riv"),

    autoplay: true,
  });

  const startInput = useStateMachineInput(rive, "rolling", "Start");
  const loadingInput = useStateMachineInput(rive, "rolling", "Loading", 0);

  useEffect(() => {
    if (rive && startInput && loadingInput) {
      console.log("Rive instance is ready.");
      // Fire the start trigger
      startInput.fire();

      // Set the loading value (example: set to 5)
      // You can dynamically change this value based on your application's state
      loadingInput.value = 30;
      startInput.fire();
    }
  }, [rive, startInput, loadingInput]);

  if (rive && startInput) {
    startInput.fire();
  }

  return <RiveComponent className="md:h-1/3 h-1/4" />;
};

export default RiveWrapper;
