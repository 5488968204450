import React from "react";
import InviteForm from "./templates/FormTemplate";
import ReactGA from "react-ga";
import { useEffect } from "react";

import { useState } from "react";

import RiveWrapper from "./RiveWrapper";
import Success from "./templates/Success";

const Join = () => {
  const questions = [
    {
      id: "q1",
      text: "What should we call you?",
      description: "We recommend using a nickname for your privacy",
      type: "open",
    },
    {
      id: "q2",
      text: "What's your email",
      description: "This will be stored securely and only used for sign up",
      type: "open",
    },
    {
      id: "q3",
      text: "Any features you would like us to include?",
      description:
        "We take your requests seriously, and we will prioritize the most requested features",
      type: "open",
    },

    // Add more questions as needed
  ];

  const [loading, setLoading] = useState(false);
  const [join, setJoin] = useState(false);
  const [loadingValue, setLoadingValue] = useState(80);

  //Google Analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleSubmit = async (answers) => {
    setLoading(true);
    ReactGA.event({
      category: "Waitlist Button",
      action: "Click",
      label: "Waitlist Button",
    });
    try {
      await fetch(
        "https://h28f74ru22.execute-api.us-east-1.amazonaws.com/dev/user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(answers),
        }
      );
      console.log("success adding events");
    } catch (error) {
      console.error(error);
      window.alert("An error occurred: " + error.message);
    }
    setLoadingValue(100);
    //wait 2 seconds
    await new Promise((resolve) => setTimeout(resolve, 2000));

    setLoading(false);
    setJoin(true);
  };

  if (loading) {
    return (
      <div className="flex flex-col h-screen justify-center bg-teal-900">
        <RiveWrapper className="" loadingValue={loadingValue} />
        <div className="text-white self-center md:text-2xl text-2xl font-bold z-10 -translate-y-44 animate-pulse animate-slow-appear"></div>
      </div>
    );
  } else if (join)
    return (
      <Success
        text="Thanks for joining. We will email you the invite link soon"
        navigate="/"
      />
    );
  return (
    <div className="h-screen w-full flex justify-center align-middle  overflow-hidden align-middle items-center">
      <div className="h-full flex items-center justify-center m-auto sm:mx-10 mx-6 overflow-hidden">
        <InviteForm questions={questions} onSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default Join;
