// Section.js
import React from "react";

const Section = ({ children, className, id }) => {
  return (
    <div
      className={`flex flex-col justify center items-center h-full flex-grow overflow-auto ${className}`}
      style={{ minHeight: "calc(100vh - 5rem)" }}
      id={id}
    >
      {children}
    </div>
  );
};

export default Section;
