import React from "react";
import { useNavigate } from "react-router-dom";

const Success = (props) => {
  const navigate = useNavigate();
  return (
    <div className="flex w-full flex-col gap-4 justify-center align-middle items-center">
      <div className="text-black text-center p-8 items-center">
        {props.text}
      </div>
      <button
        className="bg-teal-500 px-4 py-2 rounded-md text-xs text-black font-bold hover:bg-slate-200 transition"
        onClick={() => navigate(props.navigate)}
      >
        Continue
      </button>
    </div>
  );
};

export default Success;
