import React from "react";
import { Route, Routes } from "react-router-dom";
import ReactGA from "react-ga";

// ... other imports
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Join from "./components/Join";
import Waitlist from "./components/Waitlist";
import NotFound from "./components/NotFound";
ReactGA.initialize("G-BQVWGWFVP8");

function App() {
  return (
    <div className="h-screen bg-white flex flex-col justify-between text-white">
      <Navbar className="" />
      <div className="">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/join" element={<Join />} />
          <Route path="/admin/niu" element={<Waitlist />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer className="" />
    </div>
  );
}
export default App;
