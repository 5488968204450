import React from "react";
import logo from "../img/sobbly.svg";

const Logo = () => {
  return (
    <div className="flex flex-row text-white text-sm justify-center">
      <img src={logo} alt="logo" className="h-8" />
    </div>
  );
};

export default Logo;
