import React, { useState } from "react";
import RiveWrapper from "./RiveWrapper";
import { useEffect } from "react";
import ReactGA from "react-ga";

const Waitlist = () => {
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);

  //sort by date

  //Google Analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const GetUsers = async () => {
    try {
      setLoading(true);
      console.log("getting users");
      const response = await fetch(
        "https://h28f74ru22.execute-api.us-east-1.amazonaws.com/dev/allusers"
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setUsers(data);

      setTotalUsers(data.length);

      console.log("received users", data);
    } catch (error) {
      console.error("Error fetching users:", error);
      window.alert("An error occurred: " + error.message);
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    }
  };

  const Sort = () => {
    console.log("sorting");
    setUsers((users) => {
      // Ensure that currentUsers is not null
      if (!users) return [];

      // Create a new array from the current users and sort it
      const sortedUsers = [...users].sort((a, b) => {
        // Make sure to parse the dates correctly
        return new Date(b.date) - new Date(a.date);
        //oldest first
        // return new Date(a.date) - new Date(b.date);
      });
      console.log("sorted users", sortedUsers);
      return sortedUsers; // This will update the state
    });
  };

  if (loading) {
    return (
      <div className="flex flex-col h-screen justify-center bg-teal-900">
        <div className="self-center text-center w-3/5">
          Hold on while I update users
        </div>
        <RiveWrapper className="" />
        <div className="text-white self-center md:text-2xl text-2xl font-bold z-10 -translate-y-44 animate-slow-appear"></div>
      </div>
    );
  }

  if (!users) {
    return (
      <div className="flex w-full justify-center">
        <div className="flex flex-col justify-start m-8 gap-y-8 items-start">
          <h1 className="text-xl font-bold text-black">
            Hi, Niu. Click the button below to see the current sign ups.
          </h1>
          <button
            onClick={GetUsers}
            className="text-teal-700 hover:text-teal-900"
          >
            Get users
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className=" flex justify-center align-top items-start flex-grow ">
      <div className="relative sm:w-2/3 my-8">
        <div className="absolute top-0 w-full px-4 flex justify-between">
          <div className="text-black font-bold">{totalUsers} Users</div>
          <button
            className="text-black opacity-30 hover:opacity-100"
            onClick={Sort}
          >
            Newest first
          </button>

          <button
            className="text-slate-600 hover:text-black"
            onClick={GetUsers}
          >
            Refresh
          </button>
        </div>
        <div className="text-black flex flex-row flex-wrap justify-center items-center my-16">
          {users.map((user) => (
            <div
              key={user._id}
              className="flex flex-col bg-yellow-50 p-4 m-2 rounded-lg md:min-w-2/4 w-full"
            >
              {" "}
              {/* Assuming each user has a unique _id */}
              <h1 className="font-bold">{user.name}</h1>
              <h2>
                <span className=" font-light">Email</span>: {user.email}
              </h2>
              <p>
                <span className="font-light">Feature: </span>
                <span className="bg-yellow-100 px-2">{user.feature_req}</span>
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Waitlist;
