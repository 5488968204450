import React from "react";

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Section from "./templates/Section";
import logo from "../img/sobbly.svg";
import ReactGA from "react-ga";
const heroVideo = require("../img/hero-video.mp4");
const sectionVideo = require("../img/section-video.mp4");
const info = require("../img/info.mp4");

const Home = () => {
  const navigate = useNavigate();
  const handleClick = (buttonName) => {
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: buttonName,
    });
    navigate("/join");
  };
  //Google Analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="max-w-screen bg-black overflow-hidden">
      <div className=" flex flex-col font-sans text-center font-normal overflow-clip text-black">
        <Section className="z-0" id={1}>
          <video
            className="absolute top-0 left-0 object-cover w-full h-full z-0 animate-slow-appear"
            src={heroVideo}
            autoPlay
            muted
            playsInline
            preload="auto"
          />
          <div className="absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full bg-black bg-opacity-50 z-10 animate-slow-appear">
            <div className="sm:text-6xl text-4xl text-white font-sans animate-slide-in-bottom transition-all">
              Every tear <span className="font-bold">tells a story</span>
              <span className="text-sm">&copy;</span>
            </div>
          </div>
        </Section>

        <Section id={2} className="bg-black justify-center">
          <div className="flex text-white">
            <img src={logo} className="h-16" alt="logo" />
            &trade;
          </div>

          <div className="sm:text-2xl text-xl text-white sm:p-8 p-4 lg:w-2/6 w-3/4">
            <span className="font-bold select-text">Sobbly</span>
            <span className="text- font-thin">&trade;</span> allows you to log
            each time you cry and connect with others for support. Every month,
            receive a summary of your{" "}
            <span className="font-bold text-black bg-teal-100 border-teal-600 border-b-2  px-2">
              Crying Unwrapped
            </span>{" "}
            along with community stats.
          </div>
        </Section>

        <Section id={3} className="py-16 bg-white sm:text-4xl text-2xl gap-8">
          <div className="px-8">
            When we <span className="font-bold">understand</span> our tears, we
            start to <span className="">feel better.</span>
          </div>
          <div className="flex z-10 w-full items-center justify-center">
            <video
              className="object-cover md:w-2/6 z-10 animate-slow-appear"
              src={info}
              autoPlay
              muted
              loop
              playsInline
            />
          </div>
          <div className="font-sans sm:text-2xl text-lg bg-sky-100 mx-4 text-sky-600">
            You cried more{" "}
            <span className="font-bold animate-slow-appear border-sky-600 border-b-2 ">
              this week
            </span>{" "}
            than any other week
          </div>
        </Section>

        <Section
          id={4}
          className="flex flex-col-reverse h-screen  flex-grow lg:flex-row items-center justify-center  bg-black overflow-hidden"
        >
          <div className="flex flex-col h-full  gap-6 justify-center px-10 sm:px-28 sm:items-start w-full lg:w-1/2 transition-all">
            <div className="text-white sm:text-4xl lg:w-3/4 text-2xl text-start">
              And <span className="font-bold">sharing</span> them with others
              brings us together
            </div>
            <div className="text-white text-start lg:w-3/4">
              As you log your emotional moments, Sobbly
              <span className="text- font-thin">&trade;</span> provides a
              community that is ready to{" "}
              <span className=" bg-white text-black border-slate-400 border-b-2 px-1">
                support and comfort
              </span>{" "}
              when you need it most. Having such a space reminds us that we are
              never truly alone in our journey.{" "}
            </div>
            <div className="flex flex-grow-0 justify-start w-full">
              <button
                className="bg-white flex-grow-0 px-8 py-2 rounded-xl mt-4"
                onClick={() => handleClick("Home Button 1")}
              >
                Join waitlist
              </button>
            </div>
          </div>
          <div className="flex relative w-full lg:w-1/2 h-full justify-center items-center overflow-hidden">
            <div className="absolute inset-0  bg-black z-10 opacity-30 mix-blend-multiply" />
            <video
              className="absolute inset-0 h-full w-full object-cover "
              src={sectionVideo}
              autoPlay
              muted
              loop
              playsInline
            />
          </div>
        </Section>

        <Section
          id={5}
          className="gap-8 items-center justify-center  bg-teal-50"
        >
          <div className="sm:text-4xl text-2xl text-teal-800 px-8">
            Because our tears are <span className="font-bold">strength.</span>
          </div>
          <div className="text-teal-900  sm:text-center lg:w-2/4 w-3/4">
            Each month,{" "}
            <span className="font-bold">
              Sobbly<span className="text- font-thin">&trade;</span>
            </span>{" "}
            presents you with a personal{" "}
            <span className="font-bold ">Crying Unwrapped,</span> a reflective
            summary of your tears alongside community stats. This intimate
            report not only charts the moments you've shed tears, but also
            offers insights into the emotional patterns that define you.
          </div>
          <button
            className="bg-teal-700 px-8 py-2 text-white rounded-xl mt-4"
            onClick={() => handleClick("Home Button 2")}
          >
            Join waitlist
          </button>
        </Section>
      </div>
    </div>
  );
};

export default Home;
