import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex text-black justify-center">
      <div>
        <h1 className="font-bold">404</h1>
        <p>Page not found</p>
        <button onClick={() => navigate("/")} className="text-teal-700">
          Go to home
        </button>
      </div>
    </div>
  );
};

export default NotFound;
